



































import { Component, Vue } from 'vue-property-decorator';
import PageBreadcrumbs from '@/components/PageBreadcrumbs.vue';
import PageContainer from '@/components/PageContainer.vue';
import PageTitle from '@/components/PageTitle.vue';
import { MetaInfo } from 'vue-meta';
import PageDescription from '@/components/PageDescription.vue';
import { privacyPolicy as privacyPolicyFetch } from '@/service/api/module/privacyPolicy';
import { IPrivacyPolicy } from '@/types/privacyPolicy';

@Component({
  metaInfo(this: Vue & { $t: string }): MetaInfo {
    return {
      title: this.$t('views.privacyPolicy.titleSeo').toString(),
    };
  },
  components: {
    PageContainer,
    PageBreadcrumbs,
    PageTitle,
    PageDescription,
  },
})
export default class PrivacyPolicy extends Vue {
  public privacyPolicy: IPrivacyPolicy | null = null;

  private async created() {
    this.privacyPolicy = await privacyPolicyFetch();
  }
}

/* eslint-disable @typescript-eslint/camelcase */

import axios from '../axios';
import { AxiosResponse, AxiosError } from 'axios';
import { IPrivacyPolicies, IPrivacyPolicy } from '@/types/privacyPolicy';
import store from '@/store';

const CONTACT_URI = `api/v2/pages/?type=academy.PrivacyIndexPage&fields=*&locale=${store.getters['Languages/getCurrent']}`;

const privacyPolicy = async (): Promise<IPrivacyPolicy | null> => {
  return await axios
    .get(CONTACT_URI)
    .then((response: AxiosResponse) => {
      const { items }: IPrivacyPolicies = response.data;
      return items[0];
    })
    .catch((error: AxiosError) => {
      console.error(error);
      return null;
    });
};

export { privacyPolicy };
